(function ($) {
  Drupal.behaviors.stickyFooterMobileV1 = {
    attach: function (context) {
      var $elem = $('.page-footer', context);
      var $stickyFooter = $elem.find('.js-sticky-footer-mobile');

      function inViewport() {
        var elementTop = $elem?.offset()?.top;
        var elementBottom = elementTop + $elem.outerHeight(false);
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        var hideSticky = elementBottom > viewportTop && elementTop < viewportBottom;

        if (!hideSticky) {
          if ($stickyFooter.hasClass('hidden')) {
            $stickyFooter.removeClass('hidden');
          }

          return;
        }
        $stickyFooter.addClass('hidden');
      }
      $(window).on('scroll resize', _.debounce(inViewport, 300));
    }
  };
})(jQuery);
